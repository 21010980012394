import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
// Create an Axios instance
const baseURL = "https://marketinggateway.dev.bookukride.co.uk/"
// const baseURL = "https://192.168.100.22:4000/"
axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
    baseURL: baseURL,
    verify: false
   
});
function updateCookieValue(cookieName, newValue) {
    // Get the current cookies
    var cookies = document.cookie.split('; ');

    // Loop through the cookies to find the one with the specified name
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var cookieParts = cookie.split('=');
        var name = cookieParts[0];

        if (name === cookieName) {
            // Found the cookie with the specified name, update its value
            cookieParts[1] = newValue;
            document.cookie = cookieParts.join('=') + '; path=/;';
            return; // Exit the loop since the cookie has been updated
        }
    }

    // If the cookie doesn't exist, create it with the new value
    document.cookie = cookieName + '=' + newValue + '; path=/;';
}
async function refreshToken(refresh_token) {
    try {
        const response = await axios.post(baseURL + "api/token/refresh/", { "refresh": refresh_token });
        return response.data;
    } catch (error) {
        console.error('Error refreshing token:', error);
        // Handle error (e.g., redirect to login)
        window.location.href = '/';
        return null;
    }
}
async function redirectToLogin() {
    // Clear cookies and redirect
    // const refreshResponse = await axios.post(baseURL + "logout/", { withCredentials: true });
    const cookieKeys = Object.keys(Cookies.get());
    
    // Iterate through keys and remove each cookie
    cookieKeys.forEach(key => {
      Cookies.remove(key);
    });
    window.location.href = '/';
}
// Response interceptor
axiosInstance.interceptors.response.use(
    response => {
        // Success logic...
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401 && error.response.data.detail === "Invalid Session."){
            redirectToLogin();
        }
        console.log("error message", error.response.status,  error.response.data.message)
        // if (error.response.status === 403 && error.response.data.message === "You don't have permission to access this view."){
        //     redirectToLogin();
        // }
        if (error.response && error.response.status === 401 && error.response.data.detail === "Invalid Django session."){
            redirectToLogin();
        }
        // Check for 401 Unauthorized response
        if (error.response && error.response.status === 401 && error.response.data.detail === "Given token not valid for any token type") {
            // const authStateCookie = Cookies.get('_auth_state');
            // const authState = authStateCookie ? JSON.parse(authStateCookie) : {};
            // const currentTime = new Date();

            // // Check if refresh token is still valid
            // if (authState && authState["refreshTokenExpiry"]) {
            //     const refreshExpiry = new Date(authState["refreshTokenExpiry"]);
            //     if (currentTime < refreshExpiry) {
            //         // Refresh token is valid, attempt to refresh the access token
            //         const refreshResponse = await refreshToken(authState["refreshToken"]);
            //         if (refreshResponse && refreshResponse.access) {
            //             // Update the token in the cookie
            //             const newToken = refreshResponse.access;
            //             updateCookieValue('_auth', newToken);
            //             const updatedDate = new Date(currentTime.getTime() + 60 * 60 * 1000);
            //             updateCookieValue('accessTokenExpiry', updatedDate);

            //             // Update the Authorization header with the new token
            //             error.config.headers['Authorization'] = `Bearer ${newToken}`;

            //             // Retry the original request with the new token
            //             return axiosInstance(error.config);
            //         }
            //     }
            // }

            // If refresh token has expired or refreshing failed, redirect to login
            redirectToLogin();
        }

        // Return other errors without modification
        return Promise.reject(error);
    }
);


export default axiosInstance;
