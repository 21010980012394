// src/components/Login/Welcome.js

import React, { useState } from "react";
import "./login.css";
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useSignIn } from 'react-auth-kit';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import mqttService from "./mqttService";

const Welcome = () => {
  const navigate = useNavigate();
  const showAlert = useSweetAlert();
  const signIn = useSignIn();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Define the message callback function
  const handleMessage = (receivedTopic, message) => {
    console.log('Received message:', message);
    window.open(`/whatsapp_qr/${message}`, '_blank');
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission

    const cookies = document.cookie.split(";");
    cookies.forEach(cookie => {
      document.cookie = `${cookie.split("=")[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    if (username && password) {
      let params = {
        'username': username,
        'password': password
      };

      LoginService(params)
        .then(async (res) => {
          if (res.status === 200) {
            var role_check;
            if (res.data.data.tenants === "1") {
              role_check = "all";
            }

            signIn({
              token: res.data.data.access_token,
              tokenType: "Bearer",
              accessTokenExpiry: res.data.data.access_token_expiry, 
              expiresIn: 60,
              authState: {
                username: res.data.data.username,
                first_name: res.data.data.first_name,
                last_name: res.data.data.last_name,
                email: res.data.data.email,
                company: res.data.data.company,
                accessToken: res.data.data.access_token,
                accessTokenExpiry: res.data.data.access_token_expiry, 
                refreshToken: res.data.data.refresh_token,
                refreshTokenExpiry: res.data.data.refresh_token_expiry,
                role: res.data.data.role,
                access: role_check,
              }
            });
            setCookie("accessTokenExpiry", res.data.data.access_token_expiry);

            showAlert('success', {
              title: 'Successfully logged in! Redirecting...',
            });

            // MQTT Connection and Subscription
            if (role_check === "all"){
            const mqttUsername = 'developerTestClient1'; // Your MQTT username
            const mqttPassword = 'yqbSPfsNk8NfQXNC7TwRsuRM'; // Your MQTT password
            mqttService.connect('wss://mqtt.dev.bookukride.co.uk:8083/mqtt', mqttUsername, mqttPassword);
            mqttService.subscribe('MarketingGateway/whatsapp_qr', handleMessage);
            }
            var user_id = res.data.data.id;
            var currentPage = 1;
            var tenant_id = res.data.data.company;
            await sleep(2000);

            if (role_check === "accounts") {
              navigate('/user_accounts', { state: { user_id, tenant_id, currentPage } });
            } else {
              navigate('/dash');
            }
          } else {
            showAlert('warning', {
              title: 'Username or password is incorrect.',
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            showAlert('error', {
              title: err.response.data.message,
              timer: 3000,
            });
          } else {
            showAlert('error', {
              title: 'Something went wrong. Please try again.',
              timer: 3000,
            });
            console.log("err", err);
          }
        });
    }
  };

  function setCookie(name, value) {
    const date = new Date();
    date.setTime(date.getTime() + (3600000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  return (
    <div className="login-container">
      <div className="login-content">
        <h4>
          <b>Welcome!</b>
        </h4>
        <small>Connect, engage, and grow!</small>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username<span>*</span></label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Enter your username"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password<span>*</span></label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
          </div>
          <button
            style={{ backgroundColor: "#3176af", color: "white"}}
            type="submit"
            className="btn"
          >
            Login
          </button>
        </form>
        <div className="text-center">
          <Link to="/forgot_password">Forgot Password?</Link>
        </div>
        <div className="text-center">
          Don't have an Account? <Link to="/started">Register here</Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
