import React, { useEffect, useState } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import useSweetAlert from '../../Alerts/useSweetAlert';
import Layout from '../nav/navbar';
import { useNavigate } from "react-router-dom";
import axiosInstance from '../../Apis/network2';
import Cookies from 'js-cookie';

const MessageGroups = () => {
  const [groups, setGroups] = useState([]);
  const [tenant, setTenant] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [usersOptions, setUserOptions] = useState([]);
  const [createGroup, setCreateGroup] = useState({
    group_name: "",
    tenant_id: "",
    users: []
  });
  const [modalType, setModalType] = useState('add'); // 'add' for adding new group, 'edit' for adding users to existing group
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const showAlert = useSweetAlert();
  const navigate = useNavigate();

  // Calculate options for the Select component based on usersOptions state
  const options = usersOptions.map(user => ({ value: user.id, label: user.username }));
  const selectedValues = options.filter(option => createGroup.users.includes(option.value));

  const handleChangeMulti = (selectedOptions) => {
    const selectedUserIds = selectedOptions.map(option => option.value);
    setCreateGroup(prevState => ({
      ...prevState,
      users: selectedUserIds
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateGroup(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    const token = Cookies.get('_auth');
    if (modalType === 'add') {
      // Logic to create a new group
      if (createGroup.group_name !== "") {
        const params = {
          group_name: createGroup.group_name,
          tenant_id: tenant,
          users: createGroup.users
        };
        axiosInstance.post('/app/create_message_group/', params, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        })
          .then(res => {
            if (res.status === 200) {
              showAlert('success', { title: res.data.message, timer: 3000 });
              closeModal();
              get_groups();
            } else {
              showAlert('error', { title: res.data.message, timer: 3000 });
            }
          })
          .catch(err => {
            showAlert('error', { title: err.response.data.message, timer: 3000 });
          });
      } else {
        showAlert('warning', { title: "Please fill required fields.", timer: 3000 });
      }
    } else {
      // Logic to add users to an existing group
      axiosInstance.post(`/app/add_in_message_group/`, {
        users: createGroup.users,
        group_id: selectedGroupId
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then(res => {
          if (res.status === 200) {
            showAlert('success', { title: 'Users added successfully!', timer: 3000 });
            get_groups(); // Refresh the groups list
            closeModal();
          } else {
            showAlert('error', { title: res.data.message, timer: 3000 });
          }
        })
        .catch(err => {
          showAlert('error', { title: err.response.data.message, timer: 3000 });
        });
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setCreateGroup({
      group_name: "",
      tenant_id: "",
      users: []
    });
  };

  const handleAddUser = (groupId) => {
    setSelectedGroupId(groupId);
    setModalType('edit');
    setShowModal(true);
  };

  const handleAddNewGroup = () => {
    setModalType('add');
    setShowModal(true);
  };

  const get_users = (tenant) => {
    const token = Cookies.get('_auth');
    axiosInstance.get(`/get_user/?tenantId=${tenant}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    })
      .then(res => {
        if (res.status === 200) {
          setUserOptions(res.data.data);
        } else {
          showAlert('error', { title: res.data.message, timer: 3000 });
          setUserOptions([]);
        }
      })
      .catch(err => {
        showAlert('error', { title: err.response.data.message, timer: 3000 });
        setUserOptions([]);
      });
  };

  const get_groups = () => {
    const token = Cookies.get('_auth');
    axiosInstance.get('/app/create_message_group', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    })
      .then(res => {
        if (res.status === 200) {
          setGroups(res.data.data);
        } else {
          showAlert('error', { title: res.data.message, timer: 3000 });
          setGroups([]);
        }
      })
      .catch(err => {
        showAlert('error', { title: err.response.data.message, timer: 3000 });
        setGroups([]);
      });
  };

  useEffect(() => {
    const cookieValue = Cookies.get('_auth_state');
    if (!cookieValue) {
      navigate("/");
    } else {
      const authState = JSON.parse(cookieValue);
      setTenant(authState["company"]);
      get_users(authState["company"]);
      get_groups();
    }
  }, []);

  return (
    <div>
      <Layout />
      <div className="container" style={{ marginTop: "30px", fontFamily: 'Arial, sans-serif' }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1>Groups</h1>
          <Button className="btn" onClick={handleAddNewGroup} style={{ backgroundColor: "#3176af", color: "white" }}>
            Add New Group
          </Button>
        </div>
        {groups.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Users</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {groups.map(group => (
                <tr key={group.id}>
                  <td>{group.id}</td>
                  <td>{group.name}</td>
                  <td>{group.users.map(user => user.username).join(', ')}</td>
                  <td>
                    <Button variant="success" onClick={() => handleAddUser(group.id)}>Add User</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert variant="warning">No groups found</Alert>
        )}
      </div>
      {showModal && (
        <div className={`modal show`} style={{ display: "block" }} tabIndex="-1" role="dialog" aria-labelledby="addTenantModalLabel" aria-hidden={!showModal}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addTenantModalLabel">{modalType === 'add' ? 'Add New Group' : 'Add Users to Group'}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {modalType === 'add' && (
                  <div className="mb-3">
                    <label htmlFor="group_name" className="form-label">Group Name:</label>
                    <input type="text" className="form-control" id="group_name" name="group_name" value={createGroup.group_name} onChange={handleChange} maxLength="200" />
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="users" className="form-label">Users:</label>
                  <Select
                    id="users"
                    name="users"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isMulti
                    value={selectedValues}
                    onChange={handleChangeMulti}
                    options={options}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '40px',
                        fontSize: '14px',
                        borderColor: '#ced4da',
                      }),
                      dropdownIndicator: (base) => ({
                        ...base,
                        padding: '4px'
                      }),
                      clearIndicator: (base) => ({
                        ...base,
                        padding: '4px'
                      }),
                      multiValue: (base) => ({
                        ...base,
                        backgroundColor: '#e9ecef'
                      }),
                      multiValueLabel: (base) => ({
                        ...base,
                        color: 'black',
                      }),
                      multiValueRemove: (base) => ({
                        ...base,
                        color: '#495057',
                        ':hover': {
                          backgroundColor: '#e36060',
                          color: 'white',
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn" style={{ backgroundColor: "#3176af", color: "white" }} onClick={handleSubmit}>{modalType === 'add' ? 'Create Group' : 'Add Users'}</button>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageGroups;
