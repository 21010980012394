import React from "react";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginService } from "../../Apis/network";
// import { GetProfile } from "../../Apis/network";
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useSignIn } from 'react-auth-kit';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import axiosInstance from "../../Apis/network2";
import Layout from "../nav/navbar";


const Dashboard = () => {
    const [message_type, setMessageType] = useState("");
    const [custom_template, setCustomTemplate] = useState(false);
    const [emailoptions, setEmailOptions] = useState([]);
    const [emailfrom, setEmailFrom] = useState("");
    const [send_group, setSendGroup] = useState(false)
    const [groupoptions, setGroupOptions] = useState([])
    const [goupid, setGroupId] = useState("");

    const handleGroupTo = (e) => {
        setGroupId(e.target.value)
    }

    const handleGroupChange = (e) => {
        if (e.target.name === "send_group") {
            setSendGroup(true)
            var token = Cookies.get('_auth');
            axiosInstance.get('app/create_message_group', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        let data = res.data.data;
                        console.log("data", data)
                        setGroupOptions(data);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching emails:", err);
                });
        }
        else {
            setSendGroup(false)
        }
    }
    const handletemplateChange = (e) => {
        console.log("custom_template", custom_template)
        if (e.target.name === "default_template") {
            setCustomTemplate(false)
        }
        else {
            setCustomTemplate(true)
        }

    }
    const handleMessageTypeChange = (e) => {
        setMessageType(e.target.value)
        if (e.target.value === "email") {
            var token = Cookies.get('_auth');
            axiosInstance.get('app/get_emails', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        let data = res.data.data;
                        console.log("data", data)
                        setEmailOptions(data);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching emails:", err);
                });
        }

    }
    const handleEmailfrom = (e) => {
        setEmailFrom(e.target.value)
        console.log("email", e.target.value)
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <Layout />
            <div className="container mt-3" >
                <h3>Send Message:</h3>
                <form>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="custom_template" id="custom_template" onChange={handletemplateChange} checked={custom_template} />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Custom template
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="default_template" id="default_template" onChange={handletemplateChange} checked={!custom_template} />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Default template
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="send_group" id="send_group" onChange={handleGroupChange} checked={send_group} />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Send to Group
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="send_list" id="default_template" onChange={handleGroupChange} checked={!send_group} />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Send to User(Single/List)
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="exampleFormControlSelect1">Message Type</label>
                        <select className="form-control" id="message_type" name="message_type" onChange={handleMessageTypeChange}>
                            <option value="">Select an option</option>
                            <option value="sms">sms</option>
                            <option value="whatsapp">whatsapp</option>
                            <option value="email">email</option>
                        </select>
                    </div>
                    {message_type === "email" && (<div className="form-group">
                        <label for="exampleFormControlSelect1">Email send from</label>
                        <select className="form-control" id="email_from" name="email_from" onChange={handleEmailfrom}>
                            <option value="">Select an option</option>
                            {emailoptions.map((email) => (
                                <option key={email.id} value={email.id}>
                                    {email.email}
                                </option>
                            ))}
                        </select>
                    </div>)}
                    {!custom_template && (<div>
                        <div className="form-group">
                            <label for="exampleFormControlSelect1">Subject</label>
                            <input className="form-control" type="text" name="msg_subject" id="msg_subject" />

                        </div>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Message Body</label>
                            <textarea className="form-control" id="message_body" name="message_body" rows="9"></textarea>
                        </div>
                    </div>)}
                    {custom_template && (<div>
                        <div className="form-group">
                            <label for="exampleFormControlSelect1">Template</label>
                            <textarea className="form-control" type="text" name="custom_temp" id="custom_temp" rows="9"></textarea>

                        </div>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Csv File for data</label>
                            <input className="form-control" type="file" name="csv_file" id="csv_file" accept=".csv" />
                        </div>
                    </div>)}
                    {message_type === "email" && (<div>
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Attachment Files</label>
                            <input className="form-control" type="file" name="attach_files" id="attach_files" />
                        </div>
                    </div>)}
                    {send_group && (<div className="form-group">
                        <label for="exampleFormControlSelect1">Select Group</label>
                        <select className="form-control" id="select_group" name="select_group" onChange={handleGroupTo}>
                            <option value="">Select an option</option>
                            {groupoptions.length === 0 ? (
    <option disabled>No groups found</option>
) : (
    groupoptions.map((group) => (
        <option key={group.id} value={group.id}>
            {group.name}
        </option>
    ))
)}
                        </select>
                    </div>)}
                </form>
            </div>
        </>
    );
};

export default Dashboard;