import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import "./tenant_settings.css";
import axiosInstance from "../../Apis/network2";

function TenantSettings() {
  const showAlert = useSweetAlert();
  const [tenant_setting, setTenant_setting] = useState({ "id": "", "sink": [] });
  const [email_host_show, setEmailHostShow] = useState(false);
  const [edit_data, setEdit_data] = useState({});
  const [public_key, setPublickey] = useState("Public Key");
  const [selected_tab, setSelectedTab] = useState({});
  const [email_host_options, setEmailHostOptions] = useState([]);
  const [show_send_from, setShowSendfrom] = useState(true);
  const [add_data, setAdd_data] = useState({ "id": "", "email_host": "", "private_key": "", "public_key": "", "send_from": "", "sink_id": "" });
  const [showModal, setShowmodal] = useState(false);
  const [showModalCreate, setShowmodalCreate] = useState(false);
  const [tenant, setTenant] = useState("");
  const [selectedSink, setSelectedSink] = useState(null);
  const [hostdata, setHostData] = useState({ "host_name": "", "port": "", "tenant": tenant });
  const [showModalHost, setShowModalHost] = useState(false);
  const navigate = useNavigate();

  const get_tenant_setting = (get_tenant, call) => {
    var token = Cookies.get('_auth');
    axiosInstance.get('/tenant_setting?companyName=' + get_tenant, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    })
      .then((res) => {
        if (res.status === 200) {
          setTenant_setting(res.data.data);
          var data = res.data.data
          if (call === "1"){
          var matchedSink = data.sink.find(sink => sink.id === selectedSink.id);

            if (matchedSink) {
              // Update selectedSink state with the matched sink data
              setSelectedSink(matchedSink);
            }
          }
        } else {
          showAlert('error', { title: res.data.message, timer: 3000 });
          setTenant_setting({ "id": "", "sink": [] });
        }
      })
      .catch((err) => {
        showAlert('error', { title: err.response.data.message, timer: 3000 });
        setTenant_setting({ "id": "", "sink": [] });
      });
  };

  const get_host_options = (company) => {
    var token = Cookies.get('_auth');
    axiosInstance.get('/get_email_hosts/?tenant=' + company, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    })
      .then((res) => {
        if (res.status === 200) {
          setEmailHostOptions(res.data.data);
        } else {
          setEmailHostOptions([]);
        }
      })
      .catch((err) => {
        setEmailHostOptions([]);
      });
  };
  useEffect(() => {
    console.log('Tenant setting updated:', tenant_setting);
  }, [tenant_setting]);
  useEffect(() => {
    const cookieValue = Cookies.get('_auth_state');
    if (!cookieValue) {
      navigate("/");
    }
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    setTenant(authstate["company"]);
    get_tenant_setting(authstate["company"], "0");
    get_host_options(authstate["company"]);
  }, []);
  const delete_credentials = (data) => {
    console.log("data",data)
    
    var token = Cookies.get('_auth');
      axiosInstance.delete('/create_credentials/?id='+ data.id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => {
          if (res.status === 200) {
            showAlert('success', { title: res.data.message, timer: 3000 });
            get_tenant_setting(tenant, "1");
            
          } else {
            showAlert('error', { title: res.data.message, timer: 3000 });
          }
        })
        .catch((err) => {
          showAlert('error', { title: err.response.data.message, timer: 3000 });
        });
  }

  const edit_credentials = (data) => {
    setEdit_data(data);
    setShowmodal(true);
    if (data.email_host === null || data.email_host === "") {
      setEmailHostShow(false);
    } else {
      setEmailHostShow(true);
    }
    if (data.send_from === null || data.send_from === "") {
      setPublickey("Group Name");
      setShowSendfrom(false);
    } else {
      setPublickey("Public Key");
      setShowSendfrom(true);
    }
  };

  const closeModal = () => {
    setShowmodal(false);
    setEdit_data({});
    setEmailHostShow(false);
  };

  const closeModalCreate = () => {
    setShowmodalCreate(false);
    setAdd_data({ "id": "", "email_host": "", "private_key": "", "public_key": "", "send_from": "", "sink_id": "" });
    setEmailHostShow(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEdit_data((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeCreate = (e) => {
    const { name, value } = e.target;
    setAdd_data((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmitCreate = () => {
    if (email_host_show && add_data.email_host === "") {
      showAlert('warning', { title: "Please select email host.", timer: 3000 });
      return;
    }
    if (add_data.id && add_data.private_key && add_data.public_key) {
      
      var token = Cookies.get('_auth');
      axiosInstance.post('/create_credentials/', add_data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => {
          if (res.status === 200) {
            showAlert('success', { title: res.data.message, timer: 3000 });
            get_tenant_setting(tenant, "1");
            console.log("selected sink", selectedSink)
            var data = res.data.data
            
            closeModalCreate();
          } else {
            showAlert('error', { title: res.data.message, timer: 3000 });
          }
        })
        .catch((err) => {
          console.log("err", err)
          // showAlert('error', { title: err.response.data.message, timer: 3000 });
        });
    } else {
      showAlert('warning', { title: "Fill all fields.", timer: 3000 });
    }
  };

  const handleSubmit = () => {
    if (email_host_show && edit_data.email_host === "") {
      showAlert('warning', { title: "Please select email host.", timer: 3000 });
      return;
    }
    
    if (edit_data.id && edit_data.private_key && edit_data.public_key) {
      if (show_send_from){
        if (edit_data.send_from === "" || edit_data.send_from === null){
          showAlert('warning', { title: "Fill all fields.", timer: 3000 });
          return
        }
      }
      var token = Cookies.get('_auth');
      axiosInstance.post('/update_credentials/', edit_data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => {
          if (res.status === 200) {
            showAlert('success', { title: res.data.message, timer: 3000 });
            get_tenant_setting(tenant, "1");
            closeModal();
          } else {
            showAlert('error', { title: res.data.message, timer: 3000 });
          }
        })
        .catch((err) => {
          showAlert('error', { title: err.response.data.message, timer: 3000 });
        });
    } else {
      showAlert('warning', { title: "Fill all fields.", timer: 3000 });
    }
  };

  const add_new_account = (data) => {
    setAdd_data(prevData => ({ ...prevData, id: data.id }));
    setSelectedTab(data.sink_name);
    if (data.sink_name === "Email") {
      setEmailHostShow(true);
    } else {
      setEmailHostShow(false);
    }
    if (data.sink_name.toLowerCase().includes("device")) {
      setPublickey("Group Name");
      setShowSendfrom(false);
    } else {
      setPublickey("Public Key");
      setShowSendfrom(true);
    }
    setShowmodalCreate(true);
  };

  const handleSinkClick = (sink) => {
    setSelectedSink(sink);
  };

  const handleChangeHost = (e) => {
    const { name, value } = e.target;
    setHostData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmitHost = () => {
    if (hostdata.host_name && hostdata.port) {
      hostdata.tenant = tenant;
      var token = Cookies.get('_auth');
      axiosInstance.post('/get_email_hosts/', hostdata, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
        .then((res) => {
          if (res.status === 200) {
            closeopenModalHost();
            get_host_options(tenant);
          } else {
            setEmailHostOptions([]);
          }
        })
        .catch((err) => {
          setEmailHostOptions([]);
        });
    } else {
      showAlert('warning', { title: "Fill all required fields.", timer: 3000 });
    }
  };

  const openModalHost = () => {
    setShowModalHost(true);
  };

  const closeopenModalHost = () => {
    setShowModalHost(false);
    setHostData({ "host_name": "", "port": "", "tenant": tenant });
  };

  return (
    <>
      <Layout />
      <div className="container" style={{ marginTop: "30px", display: "flex", fontFamily: 'Arial, sans-serif' }}>
        {tenant !== "" && (
          <>
            <div className="sidebar" style={{ width: "20%", marginRight: "20px" }}>
              <h2>{tenant} Settings</h2>
              {tenant_setting.sink.length > 0 ? (
                tenant_setting.sink.map(sink => (
                  <div
                    key={sink.id}
                    className={`sidebar-item ${selectedSink && selectedSink.id === sink.id ? 'selected' : ''}`}
                    onClick={() => handleSinkClick(sink)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      borderBottom: "1px solid #ccc",
                      backgroundColor: selectedSink && selectedSink.id === sink.id ? '#e0e0e0' : 'transparent'
                    }}
                  >
                    {sink.sink_name}
                  </div>
                ))
              ) : (
                <div>No Access Found</div>
              )}
            </div>
            <div className="content" style={{ width: "80%" }}>
              {selectedSink ? (
                <div>
                  <h5>{selectedSink.sink_name}</h5>
                  {selectedSink.sink_credentials.length > 0 ? (
                    <div style={{ marginTop: "20px" }}>
                      {selectedSink.sink_credentials.map(credential => (
                        <div key={credential.id} className="row" style={{ marginTop: "10px" }}>
                          <div className="col-6">{credential.send_from ? credential.send_from : credential.public_key}</div>
                          <div className="col-6">
                            <button className="btn" style={{ backgroundColor: "#3176af", color: "white" }} onClick={() => edit_credentials(credential)}>Update</button>
                            <button className="btn" style={{ marginLeft:"5px", backgroundColor: "#3176af", color: "white" }} onClick={() => delete_credentials(credential)}>Delete</button>
                          </div>
                        </div>
                      ))}
                      <div>
                        <button className="btn" style={{ backgroundColor: "#3176af", color: "white" }} onClick={() => add_new_account(selectedSink)}>Add New account</button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>No settings configured</div>
                      <div>
                        <button className="btn" style={{ backgroundColor: "#3176af", color: "white" }} onClick={() => add_new_account(selectedSink)}>Add New account</button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div>Select a sink to view settings</div>
              )}
            </div>
          </>
        )}
        {showModal && (
          <div className={`modal show`} style={{ display: "block" }} tabIndex="-1" role="dialog" aria-labelledby="addTenantModalLabel" aria-hidden={!showModal}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addTenantModalLabel">Edit Account</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                </div>
                <div className="modal-body">
                  {show_send_from && (
                    <div className="mb-3">
                      <label htmlFor="send_from" className="form-label">Send From:</label>
                      <input type="text" className="form-control" id="send_from" name="send_from" value={edit_data.send_from} onChange={handleChange} maxLength="200" />
                    </div>
                  )}
                  <div className="mb-3">
                    <label htmlFor="private_key" className="form-label">Private Key:</label>
                    <input type="text" className="form-control" id="private_key" name="private_key" value={edit_data.private_key} onChange={handleChange} maxLength="200" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="public_key" className="form-label">{public_key}:</label>
                    <input type="text" className="form-control" id="public_key" name="public_key" value={edit_data.public_key} onChange={handleChange} maxLength="200" />
                  </div>
                  {email_host_show && (
                    <div className="mb-3">
                      <label htmlFor="email_host" className="form-label">Email host:</label>
                      <select className="form-control" id="email_host" name="email_host" style={{ margin: "auto" }} onChange={handleChange} value={edit_data.email_host}>
                        <option value="">Select an option</option>
                        {email_host_options.map((email) => (
                          <option key={email.id} value={email.id}>{email.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={handleSubmit}>Update Credentials</button>
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showModalCreate && (
          <div className={`modal show`} style={{ display: "block" }} tabIndex="-1" role="dialog" aria-labelledby="addTenantModalLabel" aria-hidden={!showModalCreate}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addTenantModalLabel">Create {selected_tab} Credentials:</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalCreate}></button>
                </div>
                <div className="modal-body">
                  {show_send_from && (
                    <div className="mb-3">
                      <label htmlFor="send_from" className="form-label">Send From:</label>
                      <input type="text" className="form-control" id="send_from" name="send_from" value={add_data.send_from} onChange={handleChangeCreate} maxLength="200" />
                    </div>
                  )}
                  <div className="mb-3">
                    <label htmlFor="private_key" className="form-label">Private Key:</label>
                    <input type="text" className="form-control" id="private_key" name="private_key" value={add_data.private_key} onChange={handleChangeCreate} maxLength="200" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="public_key" className="form-label">{public_key}:</label>
                    <input type="text" className="form-control" id="public_key" name="public_key" value={add_data.public_key} onChange={handleChangeCreate} maxLength="200" />
                  </div>
                  {email_host_show && (
                    <div className="mb-3">
                      <label htmlFor="email_host" className="form-label">Email host:</label>
                      <select className="form-control" id="email_host" name="email_host" style={{ margin: "auto" }} onChange={handleChangeCreate} value={add_data.email_host}>
                        <option value="">Select an option</option>
                        {email_host_options.map((email) => (
                          <option key={email.id} value={email.id}>{email.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  {email_host_show && (
                    <div className="mb-3">
                      <button className="btn" style={{ backgroundColor: "#3176af", color: "white" }} onClick={openModalHost}>Add host</button>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" style={{ backgroundColor: "#3176af", color: "white" }} onClick={handleSubmitCreate}>Create Credentials</button>
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModalCreate}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showModalHost && (
          <div className={`modal show`} style={{ display: "block" }} tabIndex="-1" role="dialog" aria-labelledby="addTenantModalLabel" aria-hidden={!showModalHost}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addTenantModalLabel">Add New Host</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModalCreate}></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="host_name" className="form-label">Host Name:</label>
                    <input type="text" className="form-control" id="host_name" name="host_name" value={hostdata.host_name} onChange={handleChangeHost} maxLength="200" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="port" className="form-label">Port:</label>
                    <input type="text" className="form-control" id="port" name="port" value={hostdata.port} onChange={handleChangeHost} maxLength="200" />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" style={{ backgroundColor: "#3176af", color: "white" }} onClick={handleSubmitHost}>Create Host</button>
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeopenModalHost}>Close</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TenantSettings;
