import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
import useSweetAlert from "../../Alerts/useSweetAlert";
import Cookies from 'js-cookie';
import axiosInstance from "../../Apis/network2";

function QrView() {
  const showAlert = useSweetAlert();
  const { token } = useParams();
  const [img_url, setImage_url] = useState(null);

  useEffect(() => {
    console.log("token", token);
    const token_header = Cookies.get('_auth');
    axiosInstance.get('/whatsapp/get_qrcode/?token=' + token, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token_header}`,
      }
    })
    .then((res) => {
      console.log("res", res);
      if (res.status === 200) {
        let data = res.data.data;
        setImage_url(data.img);
      } else {
        showAlert('error', {
          title: res.data.message,
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      console.error("Error fetching account details:", err);
      showAlert('error', {
        title: err.response.data.message,
        timer: 3000,
      });
    });
  }, [token]);

  return (
    <>
      <Layout />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center'
      }}>
        {img_url ? (
          <>
            <h2>Scan this QR</h2>
            <img src={img_url} alt="QR Code" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </>
        ) : (
          <p>No QR found</p>
        )}
      </div>
    </>
  );
}

export default QrView;
