// src/services/mqttService.js

import mqtt from 'mqtt';

class MqttService {
  constructor() {
    this.client = null;
    this.messageCallback = null;
  }

  connect(brokerUrl, username, password) {
    const options = {
      username: username,
      password: password,
      reconnectPeriod: 1000, // Automatically reconnect after 1 second
      connectTimeout: 30 * 1000 // Time to wait before timing out
    };

    this.client = mqtt.connect(brokerUrl, options);

    this.client.on('connect', () => {
      console.log('Connected to MQTT broker');
    });

    this.client.on('error', (err) => {
      console.error('MQTT connection error:', err);
    });

    this.client.on('reconnect', () => {
      console.log('Reconnecting to MQTT broker...');
    });

    this.client.on('offline', () => {
      console.log('MQTT client is offline');
    });

    this.client.on('close', () => {
      console.log('MQTT connection closed');
    });

    this.client.on('message', (receivedTopic, message) => {
      if (this.messageCallback) {
        this.messageCallback(receivedTopic, message.toString());
      }
    });
  }

  subscribe(topic, messageCallback) {
    if (this.client) {
      this.client.subscribe(topic, { qos: 1 }, (error) => {
        if (error) {
          console.error('Subscription error: ', error);
          return;
        }
        console.log(`Subscribed to topic: ${topic}`);
      });
      this.messageCallback = messageCallback;
    }
  }

  unsubscribe(topic) {
    if (this.client) {
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.error('Unsubscription error: ', error);
          return;
        }
        console.log(`Unsubscribed from topic: ${topic}`);
      });
    }
  }

  disconnect() {
    if (this.client) {
      this.client.end(() => {
        console.log('Disconnected from MQTT broker');
      });
    }
  }
}

export default new MqttService();
