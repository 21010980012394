import axios from "axios";
import Cookies from 'js-cookie';
import { useSignOut } from "react-auth-kit"
import { NavLink, useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';
const Dev_Url = "https://marketinggateway.dev.bookukride.co.uk/"
// const Dev_Url = "https://192.168.100.22:4000/"
axios.defaults.withCredentials = true;
const BACKEND_URL_ACCOUNTS = Dev_Url 

function updateCookieValue(cookieName, newValue) {
    // Get the current cookies
    var cookies = document.cookie.split('; ');

    // Loop through the cookies to find the one with the specified name
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var cookieParts = cookie.split('=');
        var name = cookieParts[0];

        if (name === cookieName) {
            // Found the cookie with the specified name, update its value
            cookieParts[1] = newValue;
            document.cookie = cookieParts.join('=') + '; path=/;';
            return; // Exit the loop since the cookie has been updated
        }
    }

    // If the cookie doesn't exist, create it with the new value
    document.cookie = cookieName + '=' + newValue + '; path=/;';
}

export function AuthCheck() {
    const signOut = useSignOut();
    const navigate = useNavigate();
    const tokenExpiry = Cookies.get('accessTokenExpiry');
    const Token = Cookies.get('auth1');
    console.log("Token Expiry", tokenExpiry);

    // Add a flag to track whether the token refresh API is already called
    let isTokenRefreshed = false;

    if (!tokenExpiry) {
        // Token expiration time is not set, consider it expired
        return true;
    }

    // Convert the token expiration time to a Date object
    const expiryDate = new Date(tokenExpiry);

    // Get the current time
    const currentTime = new Date();

    // Compare the current time with the token's expiration time
    if (currentTime >= expiryDate) {
        // Token has expired
        const cookieValueState = Cookies.get('auth_state');
        const authstate = cookieValueState ? JSON.parse(cookieValueState) : {};
        if (authstate) {
            const refresh_expiry = authstate["refreshTokenExpiry"]
            const refresh_time = new Date(refresh_expiry)
            if (currentTime >= refresh_time) {
                console.log("in second if");
                if (!isTokenRefreshed) { // Check the flag
                    isTokenRefreshed = true; // Set the flag to prevent multiple API calls
                    const refresh_token = authstate["refreshToken"]
                    let data = {
                        "refresh": refresh_token
                    }
                    console.log("params", data)
                    GetAccessToken(data)
                        .then((res) => {
                            console.log("res", res)
                            console.log("in status 200", res.status)
                            if (res.status == 200) {
                                console.log("in status 200")
                                const accessToken = res.data.accessToken
                                updateCookieValue('auth1', accessToken);
                                var currentDate = new Date();

                                // Add 5 minutes (300,000 milliseconds) to the current date
                                var updatedDate = new Date(currentDate.getTime() + 60 * 60 * 1000);
                                var updatedDateString = updatedDate.toUTCString();
                                updateCookieValue('auth_expiry', updatedDateString);
                            }
                        })
                        .catch((err) => {
                            if (err.response && err.response.data) {
                                console.log("err", err)
                            }
                        })
                }
                return true;
            } else {
                console.log("calling api")
                const refresh_token = authstate["refreshToken"]
                let data = {
                    "refresh": refresh_token
                }
                console.log("params", data)
                GetAccessToken(data)
                    .then((res) => {
                        console.log("res", res)
                        console.log("in status 200", res.status)
                        if (res.status == 200) {
                            console.log("in status 200")
                            const accessToken = res.data.accessToken
                            updateCookieValue('auth1', accessToken);
                            var currentDate = new Date();

                            // Add 5 minutes (300,000 milliseconds) to the current date
                            var updatedDate = new Date(currentDate.getTime() + 60 * 60 * 1000);
                            var updatedDateString = updatedDate.toUTCString();
                            updateCookieValue('auth_expiry', updatedDateString);
                        }
                    })
                    .catch((err) => {
                        if (err.response && err.response.data) {
                            console.log("err", err)
                        }
                    })
            }
        } else {
            return true;
        }
        return true;
    }
    console.log("token is valid")
    // Token is still valid
    return false;
}

export const refreshToken = async () => {
    try {
        const cookieValueState = Cookies.get('auth_state');
        const authstate = cookieValueState ? JSON.parse(cookieValueState) : {};
        const currentTime = new Date();
        const tokenExpiry = Cookies.get('accessTokenExpiry');
        const Token = Cookies.get('auth1');
        console.log("Token Expiry", tokenExpiry);
        const access_time = new Date(tokenExpiry)
        if (currentTime >= access_time) {
            if (!tokenExpiry) {
                // Token expiration time is not set, consider it expired
                return null;
            }
            console.log('Auth State:', authstate);
            if (authstate) {
                
                const refresh_expiry = authstate["refreshTokenExpiry"]
                const refresh_time = new Date(refresh_expiry)
                console.log('In if:', currentTime, refresh_time);
                if (currentTime >= refresh_time) {
                    return null
                    }
                else{
                    console.log("in second if");
                    const refresh_token = authstate["refreshToken"]
                    const token = localStorage.getItem('token');
                    console.log("backend url", BACKEND_URL_ACCOUNTS + "api/token/refresh/")
                    const response = await axios.post(BACKEND_URL_ACCOUNTS + "api/token/refresh/", { "refresh": refresh_token });
                    const newToken = response.data.access;
                    updateCookieValue('auth1', newToken)
                    var updatedDate = new Date(currentTime.getTime() + 60 * 60 * 1000);
                    updateCookieValue('accessTokenExpiry', updatedDate)
                    // Save the new token in local storage or cookie
                    // localStorage.setItem('token', newToken);
                    return false;
                }
                }
            else{
                return null;
            }
            }
        else{
            return false;
        }

            
        
    
} catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
}
};

export function GetAccessToken(params) {
    // AuthCheck()
return axios.post(BACKEND_URL_ACCOUNTS + "api/token/refresh/", params);
}

export function LoginService(params) {
    // AuthCheck()
return axios.post(BACKEND_URL_ACCOUNTS + "login/", params);
}

export async function get_tenants() {
    const token = Cookies.get('auth1');
    
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network')
        return axios.get(BACKEND_URL_ACCOUNTS + "accounts/add_tenant/", {headers})
    
}
export async function update_tenant(params) {
    const token = Cookies.get('auth1');
    
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network')
        return axios.put(BACKEND_URL_ACCOUNTS + "accounts/add_tenant/", params, {headers})
    
}
export async function get_accounts(params, page) {
    const token = Cookies.get('auth1');
    const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network')
        return axios.get(BACKEND_URL_ACCOUNTS + "accounts/get_accounts_detail?page="+ page +"&tenant_id=" + params, {headers})
        }

    
export async function get_account_details(params) {
    const token = Cookies.get('auth1');
    const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network111')
        return axios.get(BACKEND_URL_ACCOUNTS + "accounts/single_account_details?account_id=" + params, {headers})
    
    }

export async function get_account_transaction(params) {
    const token = Cookies.get('auth1');
    const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
    const queryString = new URLSearchParams(params).toString();
    console.log('in checkout session  api network')
        return axios.get(BACKEND_URL_ACCOUNTS + "accounts/get_transaction?"+ queryString, {headers})
    }
export async function add_new_tenant(params) {
    const token = Cookies.get('auth1');
    const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network')
        return axios.post(BACKEND_URL_ACCOUNTS + "accounts/add_tenant/" , params, {headers})
    }
    
    
    
    export async function get_users(tenant) {
        const token = Cookies.get('auth1');
        const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            
        console.log('in checkout session  api network')
            return axios.get(BACKEND_URL_ACCOUNTS + "get_user?tenantId=" + tenant, {headers})
        }

        export async function update_user(params) {
            const token = Cookies.get('auth1');
            const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                };
                
            console.log('in checkout session  api network')
                return axios.put(BACKEND_URL_ACCOUNTS + "get_user/", params, {headers})
            }
export async function add_new_account(params) {
    const token = Cookies.get('auth1');
    const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network')
        return axios.post(BACKEND_URL_ACCOUNTS + "accounts/create_account_system/" , params, {headers})
    }
    
export async function add_new_user_account(params) {
    const token = Cookies.get('auth1');
    const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network')
        return axios.post(BACKEND_URL_ACCOUNTS + "accounts/create/" , params, {headers})
    }
 
export async function active_deactive_users(params) {
    const token = Cookies.get('auth1');
    const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };
        
    console.log('in checkout session  api network')
        return axios.post(BACKEND_URL_ACCOUNTS + "user_active/" , params, {headers})
    }

    export async function approve_reject(params) {
        const token = Cookies.get('auth1');
        const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            
        console.log('in checkout session  api network')
            return axios.post(BACKEND_URL_ACCOUNTS + "accounts/approve_reject/" , params, {headers})
        }
        export async function forgot_service(params) {
            const headers = {
                    'Content-Type': 'application/json',
                };
                
            console.log('in checkout session  api network')
                return axios.post(BACKEND_URL_ACCOUNTS + "forgot-password/" , params, {headers})
            }
    export async function check_reset_link(uidb64, token ) {
        // const headers = {
        //         'Content-Type': 'application/json',
        //     };
            
        console.log('in checkout session  api network')
            return axios.get(BACKEND_URL_ACCOUNTS + "password-reset-check/" + uidb64 +"/" +token + "/")
        }
    export async function reset_password(uidb64, token , params) {
        const headers = {
                'Content-Type': 'application/json',
            };
            
        console.log('in checkout session  api network')
            return axios.post(BACKEND_URL_ACCOUNTS + "reset-password/" + uidb64 +"/" +token + "/", params)
        }
   
