import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useNavigate, useLocation } from "react-router-dom";

import Cookies from 'js-cookie';
import axiosInstance from "../../Apis/network2";
function TenantAccess() {
    const showAlert = useSweetAlert();
    const location = useLocation();

    const navigate = useNavigate();
    const [tenant, setTenant] = useState({
        "id": -1,
        "name": "",
    });
    const get_tenant_access = (name) => {
        var token = Cookies.get('_auth');
        axiosInstance.get('/get_tenant_access?tenant_id=' + name, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                console.log("res", res)
                if (res.status === 200) {
                    let data = res.data.data;
                    setTenant_resp(data);

                } else {
                    showAlert('error', {
                        title: res.data.message,
                        timer: 3000,
                    });
                    setTenant_resp([]);
                }
            })
            .catch((err) => {
                console.error("Error fetching account details:", err);
                showAlert('error', {
                    title: err.response.data.message,
                    timer: 3000,
                });
                setTenant_resp([]);
            });
    }
    const [tenant_resp, setTenant_resp] = useState([])
    useEffect(() => {
        console.log(" b component", location.state)
        if (location.state) {
            setTenant(location.state);
            const cookieValue = Cookies.get('_auth_state');

            if (!cookieValue) {
                navigate("/")
            }
            if (location.state.id !== -1) {
                get_tenant_access(location.state.name)
            }

        }
        else {
            navigate("/")
        }
    }, [location.state]);
    const handleCheckboxChange = (e, id) => {
        console.log("comment", e.target.checked, id)
        var formData = {
            "sink_id": id,
            "tenant_id": tenant.name,
            "action": e.target.checked
        }
        
        var token = Cookies.get('_auth');
        axiosInstance.post('/add_remove_access/', formData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        })
            .then((res) => {
                console.log("res", res)
                if (res.status === 200) {
                    let data = res.data.data;
                    showAlert('success', {
                        title: res.data.message,
                        timer: 3000,
                    });
                    get_tenant_access(tenant.name)

                } else {
                    showAlert('error', {
                        title: res.data.message,
                        timer: 3000,
                    });
                    // setTenant_resp([]);
                }
            })
            .catch((err) => {
                console.error("Error fetching account details:", err);
                showAlert('error', {
                    title: err.response.data.message,
                    timer: 3000,
                });
                //   setTenant_resp([]);
            });
    }
    return (
        <>
            <Layout />
            <div className="container" style={{ marginTop: "30px", display: "flex", flexDirection: "column", alignItems: "center", fontFamily: 'Arial, sans-serif' }}>
                {tenant.name !== "" && (<div>
                    <h3>{tenant.name} Access:</h3>
                    <ul style={{ listStyleType: 'none', padding: 0, marginTop: "20px" }}>
                        {tenant_resp.map(item => (
                            <li key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <span style={{ flexGrow: 1 }}>{item.sink_name}</span>
                                <input
                                    type="checkbox"
                                    checked={item.access}
                                    onChange={(e) => handleCheckboxChange(e, item.id)}
                                    style={{ marginLeft: '10px' }}
                                />
                            </li>
                        ))}
                    </ul>

                </div>)}
            </div>
        </>
    )
}
export default TenantAccess;