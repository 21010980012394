import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {  useNavigate } from 'react-router-dom';
// import "./navbar.css";
import { useSignOut } from "react-auth-kit"
import axiosInstance from "../../Apis/network2";
import Cookies from 'js-cookie';
function Layout()  {
    const navigate = useNavigate();
    const signOut = useSignOut();
    const [showperiodic, setShowperiodic] = useState(false)
    const [showtenant, setShowTenant] = useState(false)
    const logout = () => {
        // signOut();
        var token = Cookies.get('_auth');
        
        axiosInstance.post('logout/',{},{
          headers: {
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
              'X-CSRFToken': Cookies.get('csrftoken'),
                // Assuming you're using js-cookie
          },
          withCredentials: true
      }).then((res) => {
          if (res.status === 200) {
            const cookieKeys = Object.keys(Cookies.get());
    
    // Iterate through keys and remove each cookie
    cookieKeys.forEach(key => {
      Cookies.remove(key);
    });
              navigate("/")
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
        const cookies = document.cookie.split(";");
    
      // Loop through all cookies and set their expiration date to a date in the past
      
    
    
      }
      useEffect(() => {
        const cookieValue = Cookies.get('_auth_state');
        const authstate = cookieValue ? JSON.parse(cookieValue) : {};
        if (authstate){
          if(authstate["access"] === "all"){
            setShowTenant(true)
          }
          if(authstate["access"] !== "accounts"){
            setShowperiodic(true);
          }
        }
      }, []);
  return (
      <>
      
      <nav class="navbar navbar-expand-lg navbar" style={{ backgroundColor: "#3176af", color:"white"}}>
        
  <a class="navbar-brand" href="/dash" style={{color: "white"}}>Message Gateway</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="/dash" style={{color: "white"}}>Dashboard</a>
      </li>
      
      { showtenant && (<li class="nav-item">
      
        <a class="nav-link" href="/tenants" style={{color: "white"}}>Tenants</a>
      </li>)}
      <li class="nav-item">
      
        <a class="nav-link" href="/tenant_settings" style={{color: "white"}}>Settings</a>
      </li>
      <li class="nav-item">
      
        <a class="nav-link" href="/message_groups" style={{color: "white"}}>Message Groups</a>
      </li>
      <li class="nav-item">
      
        <a class="nav-link" href="/logout" style={{color: "white"}}>Logout</a>
      </li>
    </ul>
    
  </div>
</nav>
        {/* <header className="header">
          <a href="/dash" className="logo">
            BalanceTracking
          </a>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu">
          <li>
              <Link to="/dash">Home</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            { showtenant && (<li>
              <Link to="/tenants">Tenants</Link>
            </li>)}
            { showperiodic && (<li>
              <Link to="/periodic">Periodic</Link>
            </li>)}
            <li>
              <button onClick={logout}>Logout</button>
            </li>
            
          </ul>
        </header>

        <Outlet /> */}
      </>
    );
  }

export default Layout;