import React, { useEffect, useState } from "react";
import Layout from "../nav/navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import useSweetAlert from "../../Alerts/useSweetAlert";
import { useNavigate, useLocation } from "react-router-dom";

import Cookies from 'js-cookie';
import axiosInstance from "../../Apis/network2";
function Tenants()  {
  const showAlert = useSweetAlert();
  const [showModal, setShowModal] = useState(false);
    const [tenants, setTenants] = useState([])
    const [editModal, setEditModal] = useState(false)
    const [showModalcredentials, setShowModalcredentials] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        postCode: "",
        address: "",
        phoneNo: "",
        companyName: "",
        username: "",
      });
      const [createResponse, setCreateResponse] = useState({
        "username": "",
        "password": "",
        
      })
    const [editrecord, setEditrecord] = useState({
      "id": "",
      "companyName": "",
      "email": "",
      "phoneNo": "",
      "address": "",
      "postCode": "",
    })
    const closeModalcredentials = () => {
        setShowModalcredentials(false);
        setCreateResponse({
          "username": "",
          "password": "",
          
        })
      };
    const openModalcredentials = () => {
        setShowModalcredentials(true);
      };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };
      const handleSubmit = () => {
        console.log("formData", formData)
        if (formData.email !== "" && formData.postCode !== "" && formData.address !== "" && formData.phoneNo !== "" && formData.companyName !== "" ){
          formData.username = formData.email
          var token = Cookies.get('_auth');
            axiosInstance.post('/add_tenant/', formData, {
              headers:{
                'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
              }
            })
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              setCreateResponse(data);
              setFormData({
                email: "",
              postCode: "",
              address: "",
              phoneNo: "",
              companyName: "",
              })
              closeModal();
              openModalcredentials()
              get_Tenants()
              
              
            }
            else{
                console.log(" in error")
                showAlert('error', {
                    title: res.data.message,
                    timer: 3000,
                  });
                  setCreateResponse({
                    "username": "",
                    "password": "",
                    
                  });
            }
          })
          .catch((err) => {
            console.error("Error fetching accounts:", err);
    
            showAlert('error', {
                title: err.response.data.message,
                timer: 3000,
              });
              setCreateResponse({
                "username": "",
                "password": "",
                
              });
          });
          
        }
        else{
          showAlert('warning', {
            title: "All fields are required.",
            timer: 3000,
          });
        }
        
      };
    const openModal = () => {
        setShowModal(true);
      };
      const closeModal = () => {
        setShowModal(false);
        setFormData({
          email: "",
        postCode: "",
        address: "",
        phoneNo: "",
        companyName: "",
        })
      };
    const get_Tenants = () => {
      var token = Cookies.get('_auth');
       
      axiosInstance.get('/add_tenant/',{
        headers:{
          'Content-Type': 'application/json',
          // 'Cookie': yourCookie,
      'Authorization': `Bearer ${token}`,
        },
        withCredentials: true 
      },
      )
    .then((res) => {
      if (res.status === 200) {
        let data = res.data.data;
        setTenants(data);
      }
    })
    .catch((err) => {
      console.error("Error fetching tenants:", err);
    });
    }
    useEffect(() => {
        const cookieValue = Cookies.get('_auth_state');
    const authstate = cookieValue ? JSON.parse(cookieValue) : {};
    console.log("cookies", authstate["role"])
    
    if(authstate["access"] === "all"){
    //   setRole("superuser")
      
    //     setShowDiv(true)
    //     console.log("show div", showDiv)
    get_Tenants()
    }
    }, []);

    const statuschange = (id, status) => {
      var token = Cookies.get('_auth');
      var params = {
        "tenantId": id,
        "active": status
      }
       
      axiosInstance.post('tenant_active/', params,{
        headers:{
          'Content-Type': 'application/json',
          // 'Cookie': yourCookie,
      'Authorization': `Bearer ${token}`,
        },
        withCredentials: true 
      },
      )
    .then((res) => {
      if (res.status === 200) {
        let data = res.data.data;
        showAlert('success', {
          title: res.data.message,
          timer: 3000,
        });
        get_Tenants()
        
      }
    })
    .catch((err) => {
      console.error("Error fetching tenants:", err);
      showAlert('error', {
        title: err.response.data.message,
        timer: 3000,
      });
    });
    }
    const handleChangeedittenant = (e) => {
      var { name, value } = e.target;
      setEditrecord((prevData) => ({ ...prevData, [name]: value }));
    }
    const openEditModal = (accountId) => {
      var recordToEdit = tenants.find(record => record.id === accountId);
      console.log("aaaa", recordToEdit)
      setEditrecord(recordToEdit)
      setEditModal(true)
    }
    const closeEditModal = () => {
      setEditModal(false)
      setEditrecord({
        "id": "",
      "companyName": "",
      "email": "",
      "phoneNo": "",
      "address": "",
      "postCode": "",
      })
    }
    const editSubmit = () => {
    console.log("submit", editrecord)
    if (editrecord.id !== "" && (editrecord.companyName !== null && editrecord.companyName !== "") && (editrecord.email !== null && editrecord.email !== "") && (editrecord.phoneNo !== null && editrecord.phoneNo !== "") && (editrecord.address !== null && editrecord.address !== "") && (editrecord.postCode !== null && editrecord.postCode !== "")){
      var token = Cookies.get('_auth');
      axiosInstance.put('add_tenant/', editrecord,{
        headers:{
          'Content-Type': 'application/json',
          // 'Cookie': yourCookie,
      'Authorization': `Bearer ${token}`,
        },
        withCredentials: true 
      },
      )
    .then((res) => {
      if (res.status === 200) {
        let data = res.data.data;
        showAlert('success', {
          title: res.data.message,
          timer: 3000,
        });
        get_Tenants()
        closeEditModal()
        
      }
    })
    .catch((err) => {
      console.error("Error fetching tenants:", err);
      showAlert('error', {
        title: err.response.data.message,
        timer: 3000,
      });
    })
  }
    else{
      showAlert('warning', {
        title: "All fields are required.",
        timer: 3000,
      });
    }
    }
    const change_access = (id, name) =>{
        console.log("id name", id, name)
        navigate("/tenant_access", { state: { id, name} });
    }
    return (
        <>
        <Layout />
      <div className="container" style={{ marginTop: "30px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
        <div className="row">
            <div className="col-6"><h3>Tenants:</h3></div>
            <div className="col-6" style={{ display: "flex", justifyContent: "flex-end" }}><button className="btn" onClick={openModal} style={{ backgroundColor: "#3176af", color: "white"}}>Add Tenant</button></div>
        </div>
        
      <table className="table w-100">
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th className="text-center">Name</th>
                <th className="text-center">Email</th>
                <th className="text-center">Address</th>
                <th className="text-center">Post code</th>
                <th className="text-center">Active</th>
                <th className="text-center">Enable/Disable</th>
                <th className="text-center">Action</th>
                
              </tr>
            </thead>
            <tbody>
              {tenants.map((account) => (
                <tr key={account.id}>
                  <td className="text-center">{account.id}</td>
                  <td className="text-center">{account.companyName}</td>
                  <td className="text-center">{account.email}</td>
                  <td className="text-center">{account.address}</td>
                  <td className="text-center">{account.postCode}</td>
                  <td className="text-center">{account.isActive ? "Yes" : "No"}</td>
                  <td className="text-center"><button className="btn" style={{ backgroundColor: "#3176af", color: "white"}} onClick={() => statuschange(account.id, !account.isActive)}>{account.isActive ? 'Disable' : 'Enable'}</button></td>
                  <td className="text-center"> <button className="btn" style={{ backgroundColor: "#3176af", color: "white"}} onClick={() => openEditModal(account.id)} >Edit</button><button className="btn" style={{marginLeft:"5px", backgroundColor: "#3176af", color: "white"}} onClick={() => change_access(account.id, account.companyName)} >Access</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          {editModal && (
                    <div
                        className={`modal show`}
                        style={{ display: "block" }}
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="addTenantModalLabel"
                        aria-hidden={!editModal}
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addTenantModalLabel">
                                        Transfer Money
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closeEditModal}
                                    ></button>
                                </div>
                                <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                                    
                                    {/* Form fields for new tenant */}
                                    
                                    
                                    
                                    
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Name:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="companyName"
                                            name="companyName"
                                            value={editrecord.companyName}
                                            onChange={handleChangeedittenant}
                                            maxLength="20"
                                            disabled

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Email:
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={editrecord.email}
                                            onChange={handleChangeedittenant}
                                            maxLength="30"

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Phone Number:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phoneNo"
                                            name="phoneNo"
                                            value={editrecord.phoneNo}
                                            onChange={handleChangeedittenant}
                                            maxLength="20"

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Address:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            name="address"
                                            value={editrecord.address}
                                            onChange={handleChangeedittenant}
                                            

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Postal Code:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="postCode"
                                            name="postCode"
                                            value={editrecord.postCode}
                                            onChange={handleChangeedittenant}
                                            maxLength="20"

                                        />
                                    </div>
                                    
                                    
                                    
                                    

                                    {/* Add similar fields for postCode, address, phoneNo, and companyName */}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={editSubmit}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={closeEditModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>)}
                    {showModal && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Add New Tenant
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Company Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                maxlength="200"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                maxlength="50"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Phone No:
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNo"
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleChange}
                maxlength="20"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Address:
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                maxlength="1000"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Post Code:
              </label>
              <input
                type="text"
                className="form-control"
                id="postCode"
                name="postCode"
                value={formData.postCode}
                onChange={handleChange}
                maxlength="20"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={formData.email}
                onChange={handleChange}
                maxlength="20"
                disabled
              />
              
            </div>
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Create Tenant
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
    { showModalcredentials && (
      <div
      className={`modal show`}
      style={{display:"block"}}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addTenantModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addTenantModalLabel">
              Credentials for system user
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalcredentials}
            ></button>
          </div>
          <div className="modal-body">
            {/* Form fields for new tenant */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username:
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                name="username"
                value={createResponse.username}
                maxlength="200"
                disabled
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Password:
              </label>
              <input
                type="email"
                className="form-control"
                id="password"
                name="password"
                value={createResponse.password}
                maxlength="20"
                disabled
              />
            </div>
            
            
            {/* Add similar fields for postCode, address, phoneNo, and companyName */}
          </div>
          <div className="modal-footer">
            
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeModalcredentials}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>)}
      </div>

        </>
    );
  }

export default Tenants;