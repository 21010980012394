import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {  useNavigate } from 'react-router-dom';
// import "./navbar.css";
import { useSignOut } from "react-auth-kit"
import axiosInstance from "../../Apis/network2";
import Cookies from 'js-cookie';
function LogoutView()  {
    const navigate = useNavigate();
    const signOut = useSignOut();
    
      useEffect(() => {
        var token = Cookies.get('_auth');
        
        axiosInstance.post('logout/',{},{
          headers: {
            'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
              'X-CSRFToken': Cookies.get('csrftoken'),
                // Assuming you're using js-cookie
          },
          withCredentials: true
      }).then((res) => {
          if (res.status === 200) {
            const cookieKeys = Object.keys(Cookies.get());
    
    // Iterate through keys and remove each cookie
    cookieKeys.forEach(key => {
      Cookies.remove(key);
    });
              navigate("/")
          }
        })
        .catch((err) => {
          console.error("Error fetching tenants:", err);
        });
        const cookies = document.cookie.split(";");
      }, []);

      return (
        <>
        </>
    );
};

export default LogoutView;