
import logo from './logo.svg';
import Welcome from './pages/loginfolder/login';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import Dashboard from './pages/dashboard/dash';
import LogoutView from './pages/loginfolder/logout';
import Tenants from './pages/dashboard/tenants';
import TenantAccess from './pages/dashboard/tenant_access';
import TenantSettings from './pages/dashboard/tenant_settings';
import MessageGroups from './pages/dashboard/message_groups';
import QrView from './pages/dashboard/qr';
import { MqttProvider } from './contexts/MqttContext';

function App() {
  

  return (
    <ConditionalMqttProvider>
    <Routes>
      
      <Route path="/" element={<Welcome />} />
      <Route path="/dash" element={<RequireAuth loginPath={'/'}><Dashboard /></RequireAuth>} />
      <Route path="/logout" element={<RequireAuth loginPath={'/'}><LogoutView /></RequireAuth>} />
      <Route path="/tenants" element={<RequireAuth loginPath={'/'}><Tenants /></RequireAuth>} />
      <Route path="/tenant_access" element={<RequireAuth loginPath={'/'}><TenantAccess /></RequireAuth>} />
      <Route path="/tenant_settings" element={<RequireAuth loginPath={'/'}><TenantSettings /></RequireAuth>} />
      <Route path="/message_groups" element={<RequireAuth loginPath={'/'}><MessageGroups /></RequireAuth>} />
      <Route path="/whatsapp_qr/:token" element={<QrView />} />

      

    </Routes>
    </ConditionalMqttProvider>
    
  );
}
const ConditionalMqttProvider = ({ children }) => {
  const location = useLocation();

  // Check if the current route is /whatsapp_qr/:token
  const isExcludedRoute = location.pathname.startsWith('/whatsapp_qr');

  if (isExcludedRoute) {
    return children;
  }

  return <MqttProvider>{children}</MqttProvider>;
};
export default App;
